import React from 'react'

const Shapes = () => {
  return (
    <div className='shapes'>


        <svg class="shape bluecrayon"  width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2565 31.3834L22.2598 22.9385C21.5232 19.8233 24.968 17.2513 27.7873 18.8115L35.43 23.041C36.8758 22.6146 38.4779 23.0689 39.4154 24.3016L59.2169 50.3375C60.432 51.9352 60.0708 54.2355 58.4102 55.4754L49.3895 62.2105C47.7289 63.4504 45.3976 63.1603 44.1825 61.5627L24.381 35.5268C23.4434 34.294 23.4443 32.6429 24.2565 31.3834ZM26.2878 31.8353L24.075 22.4766C23.7067 20.9189 25.4291 19.633 26.8388 20.4131L35.3085 25.1002C36.1388 24.4803 37.3044 24.6253 37.912 25.4241L57.7135 51.46C58.321 52.2589 58.1404 53.409 57.3101 54.029L48.2894 60.7641C47.4591 61.384 46.2935 61.239 45.6859 60.4401L25.8845 34.4042C25.2769 33.6054 25.4575 32.4552 26.2878 31.8353Z" fill="white"/>
          <path d="M24.075 22.4768L26.2878 31.8355C25.4574 32.4554 25.2768 33.6056 25.8844 34.4045L45.6859 60.4403C46.2934 61.2392 47.4591 61.3842 48.2894 60.7643L57.3101 54.0292C58.1404 53.4092 58.321 52.2591 57.7134 51.4602L37.912 25.4244C37.3044 24.6255 36.1388 24.4805 35.3084 25.1004L26.8387 20.4133C25.4291 19.6332 23.7067 20.9191 24.075 22.4768Z" fill="#00B5CE"/>
          <rect width="15.0101" height="29.0759" transform="matrix(0.801295 -0.598269 0.605358 0.795953 26.9846 35.8507)" fill="white" fill-opacity="0.32"/>
          <ellipse cx="3.75254" cy="7.26896" rx="3.75254" ry="7.26896" transform="matrix(0.801295 -0.598269 0.605358 0.795953 34.3918 39.3916)" fill="black" fill-opacity="0.3"/>
          <path d="M43.9013 58.0941L42.7506 56.581C42.9821 56.2352 43.1599 55.8853 43.3336 55.5264C43.3659 55.4598 43.3983 55.3919 43.4313 55.3228C43.8162 54.5168 44.2748 53.5564 45.3434 52.7585C46.4554 51.9284 47.6942 51.6676 48.7129 51.4531L48.7787 51.4393C49.8639 51.2105 50.7116 51.0183 51.455 50.4633C52.1328 49.9572 52.4042 49.3943 52.8107 48.5513L52.8867 48.3938C53.1587 47.832 53.4784 47.2112 53.9972 46.5742L55.148 48.0872C54.9165 48.4331 54.7386 48.783 54.5649 49.1418C54.5327 49.2084 54.5002 49.2764 54.4672 49.3454C54.0823 50.1515 53.6237 51.1118 52.5551 51.9097C51.4432 52.7399 50.2044 53.0007 49.1856 53.2151L49.1198 53.229C48.0346 53.4578 47.1869 53.65 46.4435 54.205C45.7658 54.711 45.4943 55.2739 45.0878 56.117L45.0118 56.2745C44.7398 56.8363 44.4201 57.4571 43.9013 58.0941Z" fill="black" fill-opacity="0.3"/>
          <path d="M29.6005 39.2905L28.4498 37.7775C28.6813 37.4316 28.8592 37.0817 29.0329 36.7228C29.0651 36.6562 29.0976 36.5882 29.1305 36.5192C29.5155 35.7131 29.9741 34.7528 31.0427 33.9549C32.1546 33.1247 33.3934 32.8639 34.4122 32.6495L34.4779 32.6356C35.5632 32.4068 36.4109 32.2146 37.1543 31.6596C37.832 31.1536 38.1035 30.5907 38.51 29.7476L38.586 29.5902C38.8579 29.0284 39.1777 28.4076 39.6964 27.7706L40.8472 29.2836C40.6157 29.6295 40.4379 29.9793 40.2642 30.3382C40.2319 30.4048 40.1995 30.4727 40.1665 30.5418C39.7816 31.3479 39.323 32.3082 38.2543 33.1061C37.1424 33.9362 35.9036 34.197 34.8849 34.4115L34.8191 34.4254C33.7339 34.6541 32.8861 34.8463 32.1428 35.4013C31.465 35.9074 31.1936 36.4703 30.7871 37.3134L30.711 37.4708C30.4391 38.0326 30.1193 38.6535 29.6005 39.2905Z" fill="black" fill-opacity="0.3"/>
          <path d="M57.3101 54.0291C58.1404 53.4091 58.321 52.259 57.7135 51.4601L37.912 25.4242C37.3045 24.6254 36.1388 24.4803 35.3085 25.1003L26.8388 20.4131C26.1339 20.0231 25.3509 20.1496 24.7902 20.5682L52.7998 57.3966L57.3101 54.0291Z" fill="white" fill-opacity="0.24"/>
        </svg>

        <svg class="shape pcrayon" width="77" height="79" viewBox="0 0 77 79" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0491 28.8176L19.5083 20.3988C18.571 17.2932 21.5311 15.0626 24.2581 16.8196L31.6504 21.5825C32.947 21.2847 34.4608 21.8605 35.4269 23.1426L55.8318 50.2208C57.084 51.8825 56.9422 54.1012 55.5152 55.1765L47.7637 61.0177C46.3367 62.093 44.1649 61.6177 42.9127 59.956L22.5078 32.8778C21.5417 31.5957 21.4054 29.9819 22.0491 28.8176ZM23.958 29.4265L21.1423 20.0966C20.6737 18.5438 22.1537 17.4285 23.5172 18.307L31.7095 23.5853C32.423 23.0476 33.5089 23.2853 34.135 24.1161L54.5399 51.1943C55.166 52.0252 55.0951 53.1345 54.3816 53.6722L46.6301 59.5133C45.9166 60.051 44.8307 59.8133 44.2046 58.9825L23.7997 31.9043C23.1737 31.0735 23.2445 29.9641 23.958 29.4265Z" fill="white"/>
          <path d="M21.1422 20.0965L23.958 29.4264C23.2444 29.964 23.1736 31.0734 23.7996 31.9042L44.2045 58.9824C44.8306 59.8133 45.9165 60.0509 46.63 59.5132L54.3815 53.6721C55.095 53.1344 55.1659 52.0251 54.5398 51.1942L34.1349 24.116C33.5089 23.2852 32.4229 23.0475 31.7094 23.5852L23.5171 18.3069C22.1536 17.4284 20.6736 18.5437 21.1422 20.0965Z" fill="#B787FF"/>
          <rect x="24.9332" y="33.4083" width="12.9412" height="30.1383" transform="rotate(-37 24.9332 33.4083)" fill="white" fill-opacity="0.32"/>
          <ellipse cx="39.1698" cy="41.5491" rx="3.23529" ry="7.53457" transform="rotate(-37 39.1698 41.5491)" fill="black" fill-opacity="0.3"/>
          <path d="M42.3656 56.5421L41.1798 54.9685C41.3644 54.6495 41.4992 54.3221 41.6294 53.9856C41.6536 53.9232 41.6778 53.8594 41.7025 53.7946C41.9902 53.0384 42.333 52.1374 43.2512 51.4455C44.2067 50.7255 45.3263 50.5725 46.2471 50.4467L46.3066 50.4385C47.2874 50.3042 48.0524 50.1861 48.6912 49.7047C49.2736 49.2658 49.4769 48.738 49.7815 47.9473L49.8384 47.7997C50.0423 47.2729 50.2854 46.6924 50.7104 46.1124L51.8962 47.686C51.7117 48.0051 51.5769 48.3324 51.4466 48.6689C51.4224 48.7314 51.3982 48.7951 51.3736 48.8599C51.0858 49.6161 50.7431 50.5171 49.8248 51.2091C48.8693 51.9291 47.7497 52.0821 46.8289 52.2079L46.7695 52.216C45.7886 52.3503 45.0236 52.4685 44.3848 52.9498C43.8024 53.3887 43.5991 53.9166 43.2946 54.7072L43.2376 54.8548C43.0337 55.3816 42.7907 55.9621 42.3656 56.5421Z" fill="black" fill-opacity="0.3"/>
          <path d="M27.6289 36.9858L26.4431 35.4122C26.6277 35.0932 26.7625 34.7658 26.8928 34.4293C26.9169 34.3668 26.9412 34.3031 26.9658 34.2383C27.2535 33.4821 27.5963 32.5811 28.5146 31.8891C29.47 31.1691 30.5897 31.0161 31.5104 30.8903L31.5699 30.8822C32.5507 30.7479 33.3158 30.6297 33.9545 30.1484C34.5369 29.7095 34.7403 29.1816 35.0448 28.391L35.1018 28.2433C35.3056 27.7166 35.5487 27.1361 35.9737 26.5561L37.1595 28.1298C36.975 28.4488 36.8402 28.7761 36.71 29.1126C36.6858 29.175 36.6615 29.2388 36.6369 29.3036C36.3492 30.0598 36.0064 30.9608 35.0881 31.6527C34.1327 32.3727 33.013 32.5257 32.0923 32.6515L32.0328 32.6597C31.052 32.794 30.2869 32.9121 29.6482 33.3935C29.0658 33.8323 28.8624 34.3602 28.5579 35.1509L28.5009 35.2985C28.2971 35.8253 28.054 36.4058 27.6289 36.9858Z" fill="black" fill-opacity="0.3"/>
          <path d="M54.3815 53.6718C55.095 53.1342 55.1659 52.0248 54.5398 51.194L34.1349 24.1158C33.5088 23.2849 32.4229 23.0473 31.7094 23.5849L23.5171 18.3066C22.8353 17.8674 22.1244 17.9266 21.6426 18.2897L50.5057 56.5924L54.3815 53.6718Z" fill="white" fill-opacity="0.24"/>
        </svg>

        <svg class="shape gcrayon" width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.45594 11.7986L0.821345 4.71045C0.218343 2.09565 2.84878 -0.0158015 5.04219 1.32237L10.988 4.94987C12.0993 4.61004 13.3409 5.00877 14.0782 6.05104L29.6502 28.0634C30.6057 29.4142 30.3524 31.3344 29.0844 32.3522L22.1962 37.8814C20.928 38.8994 19.1254 38.6295 18.1699 37.2787L2.59785 15.2663C1.86054 14.2241 1.84296 12.8427 2.45594 11.7986ZM4.02882 12.2004L2.21733 4.34515C1.91584 3.03776 3.23106 1.98204 4.32777 2.65112L10.9171 6.67118C11.5511 6.16224 12.4524 6.29717 12.9302 6.97256L28.5022 28.9849C28.9799 29.6603 28.8532 30.6204 28.2192 31.1294L21.3309 36.6586C20.697 37.1676 19.7957 37.0325 19.3179 36.3572L3.7459 14.3448C3.2681 13.6695 3.39477 12.7094 4.02882 12.2004Z" fill="white"/>
          <path d="M2.21737 4.34528L4.02885 12.2005C3.39482 12.7095 3.26814 13.6696 3.74593 14.3449L19.3179 36.3573C19.7957 37.0328 20.697 37.1677 21.331 36.6587L28.2193 31.1295C28.8533 30.6205 28.98 29.6604 28.5022 28.9851L12.9302 6.97269C12.4524 6.29729 11.5511 6.16235 10.9171 6.67131L4.3278 2.65124C3.2311 1.98215 1.91587 3.03788 2.21737 4.34528Z" fill="#B5DF0A"/>
          <path d="M13.7954 8.19564L4.61108 15.5679L18.4528 35.1344L27.6371 27.7622L13.7954 8.19564Z" fill="white" fill-opacity="0.32"/>
          <path d="M19.5845 26.5567C20.8526 25.5388 20.3313 22.5236 18.4201 19.822C16.509 17.1204 13.9317 15.7555 12.6636 16.7734C11.3955 17.7913 11.9168 20.8065 13.828 23.5081C15.7391 26.2097 18.3164 27.5746 19.5845 26.5567Z" fill="black" fill-opacity="0.3"/>
          <path d="M17.9145 34.3738L17.0096 33.0944C17.1844 32.8077 17.3178 32.5173 17.4479 32.219C17.4721 32.1637 17.4964 32.1072 17.5211 32.0498C17.8093 31.38 18.1526 30.5819 18.9687 29.9269C19.8178 29.2453 20.771 29.0416 21.555 28.8741L21.6056 28.8632C22.4408 28.6845 23.093 28.5337 23.6605 28.078C24.1782 27.6626 24.3814 27.1948 24.6859 26.4942L24.7428 26.3635C24.9465 25.8966 25.1864 25.381 25.5799 24.8541L26.4848 26.1333C26.3098 26.42 26.1764 26.7106 26.0463 27.0087C26.0222 27.0642 25.9979 27.1207 25.9733 27.1779C25.6851 27.8479 25.3417 28.646 24.5256 29.3009C23.6766 29.9824 22.7233 30.1862 21.9394 30.3538L21.8888 30.3645C21.0536 30.5432 20.4014 30.6942 19.8338 31.1499C19.3163 31.5653 19.113 32.0329 18.8085 32.7335L18.7516 32.8644C18.5479 33.3311 18.308 33.8469 17.9145 34.3738Z" fill="black" fill-opacity="0.3"/>
          <path d="M6.66824 18.4758L5.76331 17.1966C5.93818 16.91 6.07159 16.6193 6.2017 16.3211C6.22585 16.2658 6.25014 16.2093 6.27484 16.1519C6.56303 15.4821 6.90639 14.684 7.7224 14.0289C8.57146 13.3475 9.52477 13.1437 10.3087 12.9762L10.3594 12.9653C11.1944 12.7866 11.8466 12.6358 12.4143 12.1801C12.9318 11.7647 13.1351 11.297 13.4395 10.5964L13.4966 10.4656C13.7001 9.99876 13.9402 9.48314 14.3335 8.9563L15.2384 10.2355C15.0636 10.5221 14.9302 10.8128 14.8001 11.1109C14.776 11.1663 14.7517 11.2227 14.727 11.2801C14.4387 11.95 14.0955 12.748 13.2794 13.4031C12.4304 14.0845 11.4771 14.2883 10.693 14.4558L10.6424 14.4667C9.80735 14.6454 9.15514 14.7962 8.58752 15.2519C8.06998 15.6674 7.8667 16.135 7.56222 16.8357L7.50528 16.9665C7.3016 17.4334 7.06164 17.9489 6.66824 18.4758Z" fill="black" fill-opacity="0.3"/>
          <path d="M28.2194 31.1294C28.8535 30.6204 28.9802 29.6603 28.5023 28.9849L12.9303 6.97256C12.4526 6.29718 11.5513 6.16224 10.9173 6.67118L4.32795 2.65112C3.7796 2.31657 3.17663 2.41324 2.74841 2.75696L24.7753 33.8939L28.2194 31.1294Z" fill="white" fill-opacity="0.24"/>
        </svg>

        <svg class="shape crayon4" width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.01744 16.4435L5.2407 9.06608C4.58527 6.34456 7.44444 4.14692 9.82858 5.53971L16.2914 9.31527C17.4994 8.96157 18.8489 9.37657 19.6503 10.4614L36.5764 33.3722C37.615 34.7782 37.3397 36.7767 35.9614 37.8361L28.4742 43.591C27.0958 44.6505 25.1364 44.3695 24.0978 42.9637L7.17169 20.0527C6.37027 18.968 6.35116 17.5303 7.01744 16.4435ZM8.7271 16.8617L6.75808 8.68587C6.43038 7.32511 7.85995 6.2263 9.05203 6.92269L16.2144 11.1068C16.9034 10.5771 17.8831 10.7176 18.4025 11.4205L35.3286 34.3313C35.8478 35.0343 35.7102 36.0336 35.0209 36.5633L27.5337 42.3182C26.8447 42.848 25.865 42.7074 25.3456 42.0046L8.41956 19.0936C7.90022 18.3908 8.03791 17.3915 8.7271 16.8617Z" fill="white"/>
          <path d="M6.75817 8.68593L8.72717 16.8618C8.038 17.3915 7.90031 18.3908 8.41964 19.0937L25.3457 42.0046C25.8651 42.7076 26.8447 42.848 27.5338 42.3183L35.0212 36.5634C35.7102 36.0336 35.848 35.0344 35.3287 34.3315L18.4026 11.4206C17.8832 10.7176 16.9035 10.5772 16.2144 11.1069L9.05211 6.92275C7.86004 6.22635 6.43045 7.32517 6.75817 8.68593Z" fill="#FF8CFF"/>
          <path d="M19.3429 12.6934L9.35999 20.3666L24.4054 40.7318L34.3883 33.0586L19.3429 12.6934Z" fill="white" fill-opacity="0.32"/>
          <path d="M25.6354 31.8039C27.0137 30.7444 26.4471 27.6061 24.3698 24.7943C22.2924 21.9824 19.491 20.5618 18.1127 21.6213C16.7343 22.6807 17.301 25.819 19.3783 28.6309C21.4556 31.4427 24.257 32.8633 25.6354 31.8039Z" fill="black" fill-opacity="0.3"/>
          <path d="M23.8203 39.94L22.8367 38.6085C23.0267 38.3101 23.1717 38.0078 23.3131 37.6973C23.3393 37.6398 23.3657 37.581 23.3926 37.5212C23.7059 36.8241 24.079 35.9934 24.966 35.3116C25.889 34.6023 26.9251 34.3903 27.7773 34.2159L27.8323 34.2046C28.7401 34.0186 29.449 33.8616 30.0659 33.3873C30.6285 32.955 30.8495 32.4681 31.1804 31.7389L31.2423 31.6028C31.4637 31.1169 31.7245 30.5803 32.1521 30.0319L33.1357 31.3633C32.9456 31.6616 32.8006 31.9641 32.6592 32.2744C32.6329 32.3321 32.6065 32.3909 32.5798 32.4505C32.2665 33.1478 31.8932 33.9785 31.0062 34.6601C30.0834 35.3695 29.0471 35.5816 28.1951 35.756L28.1401 35.7671C27.2323 35.9531 26.5234 36.1103 25.9064 36.5846C25.3439 37.0169 25.1229 37.5037 24.792 38.2328L24.7301 38.3691C24.5087 38.8549 24.2479 39.3916 23.8203 39.94Z" fill="black" fill-opacity="0.3"/>
          <path d="M11.5961 23.3932L10.6124 22.0618C10.8025 21.7634 10.9475 21.4609 11.0889 21.1505C11.1152 21.0929 11.1416 21.0341 11.1684 20.9744C11.4817 20.2773 11.8549 19.4466 12.7419 18.7648C13.6648 18.0556 14.701 17.8434 15.5531 17.6691L15.6081 17.6578C16.5158 17.4718 17.2247 17.3148 17.8418 16.8405C18.4043 16.4081 18.6252 15.9213 18.9561 15.1921L19.0182 15.056C19.2394 14.5701 19.5004 14.0335 19.9279 13.4851L20.9115 14.8165C20.7215 15.1149 20.5765 15.4173 20.4351 15.7277C20.4088 15.7853 20.3824 15.844 20.3555 15.9038C20.0422 16.601 19.6691 17.4316 18.7821 18.1134C17.8593 18.8226 16.823 19.0348 15.9708 19.2091L15.9158 19.2204C15.0081 19.4064 14.2992 19.5634 13.6822 20.0377C13.1197 20.4701 12.8987 20.9568 12.5678 21.6861L12.5059 21.8222C12.2845 22.3082 12.0237 22.8448 11.5961 23.3932Z" fill="black" fill-opacity="0.3"/>
          <path d="M35.0211 36.5633C35.7103 36.0335 35.848 35.0343 35.3286 34.3313L18.4025 11.4205C17.8833 10.7175 16.9036 10.5771 16.2144 11.1068L9.0521 6.92263C8.45606 6.57442 7.80066 6.67504 7.33521 7.03279L31.2775 39.4407L35.0211 36.5633Z" fill="white" fill-opacity="0.24"/>
        </svg>

        <svg class="shape yellowcrayon" width="53" height="46" viewBox="0 0 53 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.43847 14.8314L5.55513 8.17725C4.86038 5.72255 7.8911 3.74036 10.4183 4.99661L17.2689 8.40202C18.5493 8.08299 19.9798 8.4573 20.8293 9.43577L38.771 30.1004C39.872 31.3686 39.5801 33.1712 38.1191 34.1267L30.1827 39.3174C28.7215 40.273 26.6446 40.0196 25.5437 38.7516L7.60199 18.0868C6.75246 17.1084 6.73222 15.8117 7.43847 14.8314ZM9.25072 15.2086L7.16356 7.83432C6.81618 6.60697 8.33155 5.61588 9.59515 6.244L17.1872 10.0179C17.9176 9.54016 18.9561 9.66683 19.5066 10.3009L37.4483 30.9655C37.9987 31.5996 37.8527 32.5009 37.1223 32.9787L29.1858 38.1694C28.4553 38.6472 27.4169 38.5204 26.8663 37.8865L8.92473 17.2217C8.37423 16.5878 8.52018 15.6864 9.25072 15.2086Z" fill="white"/>
          <path d="M7.16361 7.83436L9.25075 15.2087C8.52023 15.6863 8.37428 16.5877 8.92477 17.2217L26.8664 37.8865C27.4169 38.5205 28.4554 38.6472 29.1858 38.1694L37.1224 32.9788C37.8528 32.5009 37.9987 31.5996 37.4483 30.9655L19.5067 10.3009C18.9561 9.66686 17.9177 9.54019 17.1873 10.018L9.59519 6.24403C8.3316 5.61591 6.81623 6.60701 7.16361 7.83436Z" fill="#FFC700"/>
          <path d="M20.5036 11.4488L9.92163 18.3696L25.8697 36.7382L36.4516 29.8174L20.5036 11.4488Z" fill="white" fill-opacity="0.32"/>
          <path d="M27.1735 28.6858C28.6345 27.7302 28.0339 24.8996 25.8319 22.3634C23.63 19.8272 20.6605 18.5459 19.1994 19.5015C17.7384 20.457 18.339 23.2877 20.541 25.8238C22.743 28.36 25.7124 29.6414 27.1735 28.6858Z" fill="black" fill-opacity="0.3"/>
          <path d="M25.2495 36.0242L24.2069 34.8233C24.4083 34.5542 24.562 34.2813 24.7119 34.0015C24.7397 33.9496 24.7677 33.8965 24.7962 33.8426C25.1283 33.2137 25.5238 32.4646 26.464 31.8497C27.4424 31.2098 28.5407 31.0187 29.444 30.8614L29.5023 30.8512C30.4646 30.6834 31.216 30.5417 31.8699 30.114C32.4663 29.724 32.7005 29.2849 33.0513 28.6272L33.1169 28.5045C33.3516 28.0662 33.628 27.5822 34.0813 27.0875L35.1239 28.2884C34.9223 28.5575 34.7686 28.8304 34.6188 29.1102C34.5909 29.1623 34.5629 29.2152 34.5346 29.2691C34.2025 29.898 33.8069 30.6472 32.8666 31.262C31.8884 31.9019 30.79 32.093 29.8869 32.2505L29.8286 32.2605C28.8663 32.4283 28.1148 32.57 27.4608 32.9977C26.8645 33.3877 26.6303 33.8268 26.2795 34.4845L26.214 34.6074C25.9793 35.0455 25.7028 35.5297 25.2495 36.0242Z" fill="black" fill-opacity="0.3"/>
          <path d="M12.2918 21.0998L11.2491 19.8989C11.4506 19.6298 11.6043 19.357 11.7543 19.077C11.7821 19.0251 11.8101 18.972 11.8385 18.9181C12.1706 18.2894 12.5662 17.5401 13.5064 16.9252C14.4846 16.2853 15.583 16.0942 16.4863 15.9369L16.5446 15.9267C17.5068 15.7589 18.2582 15.6173 18.9122 15.1895C19.5085 14.7995 19.7427 14.3604 20.0935 13.7028L20.1592 13.5799C20.3938 13.1417 20.6704 12.6577 21.1235 12.1631L22.1661 13.364C21.9647 13.6331 21.811 13.9059 21.6611 14.1858C21.6333 14.2378 21.6053 14.2908 21.5768 14.3447C21.2447 14.9735 20.8492 15.7227 19.909 16.3375C18.9308 16.9774 17.8324 17.1687 16.9291 17.326L16.8708 17.336C15.9086 17.5038 15.1571 17.6455 14.5031 18.0733C13.9068 18.4633 13.6726 18.9023 13.3218 19.56L13.2562 19.6829C13.0215 20.1212 12.7451 20.6052 12.2918 21.0998Z" fill="black" fill-opacity="0.3"/>
          <path d="M37.1223 32.9787C37.8529 32.5008 37.9988 31.5995 37.4483 30.9654L19.5066 10.3008C18.9562 9.66673 17.9178 9.54006 17.1872 10.0178L9.59517 6.24392C8.96338 5.92985 8.26865 6.02059 7.77527 6.34326L33.1541 35.5739L37.1223 32.9787Z" fill="white" fill-opacity="0.24"/>
        </svg>


        <svg class="shape browncrayon" width="50" height="43" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.01744 13.8641L5.2407 7.64393C4.58527 5.34931 7.44444 3.4964 9.82858 4.67072L16.2914 7.85405C17.4994 7.55582 18.8489 7.90572 19.6503 8.82037L36.5764 28.1373C37.615 29.3228 37.3397 31.0078 35.9614 31.901L28.4742 36.7532C27.0958 37.6465 25.1364 37.4096 24.0978 36.2243L7.17169 16.9072C6.37027 15.9926 6.35116 14.7805 7.01744 13.8641ZM8.7271 14.2167L6.75808 7.32338C6.43038 6.17607 7.85995 5.24961 9.05203 5.83677L16.2144 9.36457C16.9034 8.91795 17.8831 9.03636 18.4025 9.62905L35.3286 28.946C35.8478 29.5387 35.7102 30.3813 35.0209 30.8279L27.5337 35.6801C26.8447 36.1267 25.865 36.0082 25.3456 35.4156L8.41956 16.0985C7.90022 15.5058 8.03791 14.6633 8.7271 14.2167Z" fill="white"/>
          <path d="M6.75817 7.32332L8.72717 14.2167C8.038 14.6632 7.90031 15.5057 8.41964 16.0985L25.3457 35.4156C25.8651 36.0081 26.8447 36.1267 27.5338 35.68L35.0212 30.8279C35.7102 30.3812 35.848 29.5387 35.3287 28.9459L18.4026 9.62899C17.8832 9.0363 16.9035 8.91789 16.2144 9.36451L9.05211 5.8367C7.86004 5.24955 6.43045 6.17601 6.75817 7.32332Z" fill="#9A6533"/>
          <path d="M19.3429 10.7021L9.35999 17.1716L24.4054 34.3423L34.3883 27.8728L19.3429 10.7021Z" fill="white" fill-opacity="0.32"/>
          <path d="M25.6354 26.815C27.0137 25.9217 26.4471 23.2757 24.3698 20.905C22.2924 18.5342 19.491 17.3364 18.1127 18.2297C16.7343 19.1229 17.301 21.7689 19.3783 24.1397C21.4556 26.5105 24.257 27.7083 25.6354 26.815Z" fill="black" fill-opacity="0.3"/>
          <path d="M23.8203 33.6747L22.8367 32.5522C23.0267 32.3006 23.1717 32.0456 23.3131 31.784C23.3393 31.7354 23.3657 31.6859 23.3926 31.6355C23.7059 31.0476 24.079 30.3474 24.966 29.7725C25.8889 29.1744 26.9251 28.9957 27.7773 28.8487L27.8323 28.8391C28.74 28.6823 29.449 28.5498 30.0659 28.1501C30.6285 27.7855 30.8495 27.375 31.1804 26.7602L31.2423 26.6455C31.4637 26.2357 31.7245 25.7833 32.152 25.3209L33.1357 26.4435C32.9456 26.695 32.8006 26.9501 32.6592 27.2117C32.6329 27.2604 32.6065 27.3098 32.5796 27.3602C32.2665 27.9481 31.8932 28.6485 31.0062 29.2232C30.0834 29.8213 29.0471 30 28.1951 30.1471L28.1399 30.1565C27.2323 30.3134 26.5234 30.4459 25.9064 30.8456C25.3439 31.2102 25.1229 31.6207 24.792 32.2355L24.7301 32.3503C24.5087 32.7599 24.2478 33.2125 23.8203 33.6747Z" fill="black" fill-opacity="0.3"/>
          <path d="M11.5961 19.7236L10.6124 18.6011C10.8025 18.3495 10.9475 18.0945 11.0889 17.8327C11.1152 17.7842 11.1416 17.7346 11.1684 17.6842C11.4817 17.0965 11.8549 16.3961 12.7419 15.8213C13.6648 15.2232 14.701 15.0444 15.5531 14.8974L15.6081 14.8879C16.5158 14.7311 17.2247 14.5987 17.8418 14.1988C18.4043 13.8343 18.6252 13.4238 18.9561 12.809L19.0182 12.6942C19.2394 12.2846 19.5004 11.8321 19.9279 11.3698L20.9115 12.4923C20.7215 12.7439 20.5765 12.9989 20.4351 13.2606C20.4088 13.3091 20.3824 13.3587 20.3555 13.409C20.0422 13.9968 19.6691 14.6972 18.7821 15.2721C17.8593 15.87 16.823 16.0489 15.9708 16.1959L15.9158 16.2053C15.0081 16.3623 14.2992 16.4946 13.6822 16.8945C13.1197 17.2591 12.8987 17.6695 12.5678 18.2842L12.5059 18.3991C12.2845 18.8088 12.0237 19.2613 11.5961 19.7236Z" fill="black" fill-opacity="0.3"/>
          <path d="M35.0211 30.8279C35.7103 30.3812 35.848 29.5387 35.3286 28.9459L18.4025 9.62899C17.8833 9.0363 16.9036 8.91789 16.2144 9.36451L9.0521 5.8367C8.45606 5.54313 7.80066 5.62795 7.33521 5.92958L31.2775 33.2539L35.0211 30.8279Z" fill="white" fill-opacity="0.24"/>
        </svg>


    </div>
  )
}

export default Shapes;
